import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import { UserContextProvider } from "./context/userContext";
import { ColorModeContextProvider } from "./context/themeContext";
import "./i18n";
import { useUserContext } from "./context/userContext";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import LoadingPage from "./components/Loading/loadingScreen";

function AuthIsLoaded({ children }) {
  const { authLoading } = useUserContext();
  if (authLoading) return <LoadingPage />;
  return children;
}

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <AuthIsLoaded>
        <ColorModeContextProvider>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<LoadingPage />}>
              <App />
            </Suspense>
          </ThemeProvider>
        </ColorModeContextProvider>
      </AuthIsLoaded>
    </UserContextProvider>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
