import React, { createContext, useState, useContext, useEffect } from "react";
import { auth, firestore } from "../firebase/firebase";
import {
  doc,
  setDoc,
  serverTimestamp,
  onSnapshot,
  collection,
  where,
  query,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "@firebase/auth";
import * as ROLES from "../constants/roles";

const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authAlert, setAuthAlert] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [pathways, setPathways] = useState([]);
  const [pathwaysV3, setPathwaysV3] = useState([]);
  const [guides, setGuides] = useState([]);
  const [authError, setAuthError] = useState("");

  useEffect(() => {
    setAuthLoading(true);
    let unSub = false;
    const unsubscribeAuth = onAuthStateChanged(auth, (res) => {
      if (res) {
        setIsLogged(true);
        unSub = onSnapshot(doc(firestore, "users", res.uid), (doc) => {
          setUser({ id: doc.id, ...doc.data() });
        });
      } else {
        setUser(null);
      }
      setAuthError("");
      setAuthLoading(false);
    });

    return () => {
      unsubscribeAuth();
      if (unSub) unSub();
    };
  }, []);

  useEffect(() => {
    if (user === undefined || user === null) {
      return;
    }

    let q = query(
      collection(firestore, "pathways_v2"),
      where("owner", "==", user.uid),
      where("deleted", "!=", true)
    );

    let j = query(
      collection(firestore, "pathways_v3"),
      where("creator", "==", user.uid),
      where("deleted", "!=", true)
    );

    let g = query(
      collection(firestore, "series"),
      where("owner", "==", user.uid),
      where("deleted", "!=", true)
    );

    const pathwaysV2Listener = onSnapshot(q, (querySnapshot) => {
      const paths = [];
      querySnapshot.forEach((doc) => {
        paths.push({ ...doc.data(), id: doc.id });
      });
      paths.sort((a, b) => b.lastActivity.time - a.lastActivity.time);
      setPathways(paths);
    });

    const pathwaysV3Listener = onSnapshot(j, (querySnapshot) => {
      const paths = [];
      querySnapshot.forEach((doc) => {
        paths.push({ ...doc.data(), id: doc.id });
      });
      paths.sort((a, b) => b.lastActivity.time - a.lastActivity.time);
      setPathwaysV3(paths);
    });

    const userGuides = onSnapshot(g, (querySnapshot) => {
      const guides = [];
      querySnapshot.forEach((doc) => {
        guides.push({ ...doc.data(), id: doc.id });
      });
      guides.sort((a, b) => {
        // sort by favorite
        if (a.isFavorite && !b.isFavorite) {
          return -1;
        } else if (!a.isFavorite && b.isFavorite) {
          return 1;
        }

        // sort by createdAt
        if (a.lastActivity.time < b.lastActivity.time) {
          return 1;
        } else if (a.lastActivity.time > b.lastActivity.time) {
          return -1;
        }
      });

      setGuides(guides);
    });

    return () => {
      pathwaysV2Listener();
      pathwaysV3Listener();
      userGuides();
    };
  }, [user]);

  const registerUser = async ({ email, firstName, lastName, password }) => {
    setAuthLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (user) {
        await setDoc(doc(firestore, "users", user.uid), {
          firstName,
          lastName,
          fullName: `${firstName} ${lastName}`,
          initials: `${firstName[0]}${lastName[0]}`,
          email,
          role: ROLES.SUPERADMIN,
          userAdded: serverTimestamp(),
        });
      }
    } catch (error) {
      setAuthError(error);
    } finally {
      setAuthLoading(false);
    }
  };

  const signInUser = async ({ email, password }) => {
    setAuthLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setAuthError(error.message);
      setAuthAlert(true);
    } finally {
      setAuthLoading(false);
    }
  };

  const logoutUser = () => signOut(auth);
  const forgotPassword = ({ email }) => sendPasswordResetEmail(auth, email);
  const closeAuthAlert = () => setAuthAlert(false);

  const contextValue = {
    user,
    isLogged,
    pathways,
    pathwaysV3,
    guides,
    authLoading,
    authAlert,
    authError,
    registerUser,
    signInUser,
    logoutUser,
    forgotPassword,
    closeAuthAlert,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
