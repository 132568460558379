import React from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

import MetaStudiosGreenM from "../../images/meta_studios_green_m_animate";

import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: 16,
    },
    paddingTop: 16,
  },
  block: {
    minWidth: 100,
  },
}));

export default function LoadingBlock({ text }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        xs={12}
      >
        <Grid item xs={1} align="center" className={classes.block}>
          <CircularProgress />
          <br />
          {text ? text : `Copying...`}
        </Grid>
      </Grid>
    </div>
  );
}
