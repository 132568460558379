/*****************************************************************************/
/*  File Name: index.js                                                      */
/*  Date:      Dec 1, 2021                                                   */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This is a base for popups and error messages to be shown      */
/*                                                                           */
/*****************************************************************************/

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

//import firebase from 'firebase/app';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LoadingBlock from '../Loading';

function Error(props) {
  //Load all of the default component varaibles

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ error: null, errorInfo: null });

  //Load all of the prop variables
  //const auth = useSelector((state) => state.firebase.auth);

  useEffect(() => {
    //First thing we need to do is get the users company info
    async function effectFunction(params) {
      setLoading(false);
      setError({ error: null, errorInfo: null });
    }

    effectFunction();
  }, []);

  if (error.errorInfo) {
    return (
      <Grid item xs container spacing={2} alignItems='center' justify='center'>
        <Grid item xs={12}>
          <Typography variant='h6'>Sign Up???</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container>
          {!loading ? (
            <Grid item xs={12} container>
              Loaded Content
            </Grid>
          ) : (
            <Grid item xs={12} align='center'>
              <LoadingBlock />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
  return props.children;
}

export default withTranslation()(Error);
