/*****************************************************************************/
/*  File Name: index.js                                                      */
/*  Date:      Dec 1, 2021                                                   */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This is a base for popups and error messages to be shown      */
/*                                                                           */
/*****************************************************************************/

import React from 'react';
import { withTranslation } from 'react-i18next';

//import firebase from 'firebase/app';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useUserContext } from '../../context/userContext';

function Alerts(props) {
  //Load all of the default component varaibles
  const { authError, authAlert, closeAuthAlert } = useUserContext();
  const vertical = 'bottom';
  const horizontal = 'center';

  return (
    <div className='alerts'>
      <Snackbar open={authAlert} autoHideDuration={6000} onClose={closeAuthAlert} anchorOrigin={{ vertical: vertical, horizontal: horizontal }}>
        <Alert onClose={closeAuthAlert} variant='filled' severity='error'>
          Auth Error - {authError}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default withTranslation()(Alerts);
