// theme.js
import { createTheme } from "@mui/material/styles";
import { DARK_BLUE } from "./constants/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: DARK_BLUE,
    },
  },
});

export default theme;
